import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LeaseMagicLinkAsync from 'asyncHandlers/LeaseMagicLinkAsync';
import CopyTextButton from 'components/CopyTextButton';
import FlashNotice from 'components/FlashNotice';
import NoticeComponent from 'components/NoticeComponent';

const ButtonComponent = props => {
  const { lease_id: leaseId, flashNotice } = props;
  const [magicLink, updateMagicLink] = useState('');

  useEffect(() => {
    const handler = new LeaseMagicLinkAsync(leaseId);
    handler
      .magicLink
      .then(link => updateMagicLink(link));
    return () => {
      handler.cancel();
    };
  }, [leaseId]);

  return (
    <CopyTextButton
      text={magicLink}
      id="copy-magic-link-clipboard"
      callback={flashNotice}
    >
      <div className="button btn-rounded btn-black-white">
        <div className="invite-url-wrapper">
          <i className="nookons-copy" />
          Invite URL
        </div>
      </div>
    </CopyTextButton>
  );
};

const LeaseMagicLinkButton = props => (
  <FlashNotice
    noticeComponent={<NoticeComponent text="URL Copied" />}
  >
    <ButtonComponent {...props} />
  </FlashNotice>
);

ButtonComponent.propTypes = {
  flashNotice: PropTypes.func.isRequired,
  lease_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

LeaseMagicLinkButton.propTypes = {
  lease_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default LeaseMagicLinkButton;
