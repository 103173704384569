import React from 'react';
import { connect } from 'react-redux';

import loginImg from 'images/illustrations/Login_Illustration.png';
import { login, submitForm } from '../../reducers/auth';

const LoginForm = (props) => {
  const {
    name,
    handleSubmit,
    error,
    closeModal,
    submitting,
    handleSignup,
    remove_signup_button,
  } = props;

  return (
    <div className="login-page">
      <div className="row no-no-gutters">
        <div className="col-md-6 login-form-side">
          <button className="close" onClick={closeModal}>
            <i className="nookons-close-circle-fill" />
          </button>
          <h1 id="login-headline">Login</h1>
          <form onSubmit={handleSubmit} name={name}>
            {error && <p className="error">{String(error)}</p>}
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name="authenticity_token" value="" />


            <label className="auth-label">Email</label>
            <div className="input-group">
              <div className="ipnut-group-prepend">
                <span className="input-group-text"><i className="nookons-email-fill" /></span>
              </div>
              <input
                placeholder="Email"
                className="general-text-field mb-3 form-control"
                type="email"
                name="agent[email]"
                id="agent_email"
                style={error ? { borderColor: 'red' } : {}}
              />
            </div>
            <label className="auth-label">Password</label>
            <div className="input-group">
              <div className="ipnut-group-prepend">
                <span className="input-group-text"><i className="nookons-lock-fill" /></span>
              </div>
              <input
                placeholder="Password"
                className="general-text-field form-control"
                type="password"
                name="agent[password]"
                id="agent_password"
                style={error ? { borderColor: 'red' } : {}}
              />
            </div>
            <div className="row login-action-row">
              <div className="col-sm-6">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="agent[remember_me]"
                    id="agent_remember_me"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <label
                    style={{ paddingLeft: '5px' }}
                    className="custom-control-label mb-0 auth-notes"
                    htmlFor="agent_remember_me"
                  >
                    Keep me logged in
                  </label>
                </div>
              </div>
            </div>
            <button
              type="submit"
              name="commit"
              className="button btn-yellow-black btn-login btn-rounded w-100 mb-4"
              style={{ fontSize: '12px' }}
              disabled={submitting}
            >
              Login
              <i className="nookons-arrow-right float-right" style={{ lineHeight: '1.5' }} />
            </button>
            <div className="auth-notes">Forgot your password?{' '}
              <a id="forgot-password" href="/agents/password/new">
              Reset password
              </a>
            </div>
          </form>
          <hr/>
          {!remove_signup_button && (
            <div className="auth-notes">Don&apos;t have an account?{' '}
              <span onClick={handleSignup}>Sign up</span>
            </div>
          )}
          <div style={{ marginBottom: '45px', color: '#9F9F9F' }}>
            <a id="forgot-password" href="/agents/confirmation/new" className="auth-notes">
              Didn&apos;t receive confirmation instructions?
            </a>
          </div>
        </div>
        <div className="col-md-6 d-none d-md-inline-block auth-text border-0">
          <img alt="Login" src={loginImg} className="login-illustration-side" />
          <h4>Welcome Back</h4>
          <p>
            Yay! You’re back! Thanks for renting with Nooklyn. We knew you’d love us.
          </p>
        </div>
      </div>
    </div>
  );
};

const mapState = (state, ownProps) => ({
  name: 'sign_in',
  error: state.auth.loginError,
  submitting: state.auth.submitting,
  closeModal: ownProps.closeModal,
  handleSignup: ownProps.handleSignup,
  remove_signup_button: ownProps.remove_signup_button,
  redirect: ownProps.redirect,
});

const mergeProps = (stateProps, dispatchProps) => {
  const {
    name,
    error,
    submitting,
    closeModal,
    handleSignup,
    remove_signup_button,
    redirect,
  } = stateProps;
  const { dispatch } = dispatchProps;
  return {
    name,
    error,
    submitting,
    closeModal,
    handleSignup,
    remove_signup_button,
    handleSubmit(evt) {
      evt.preventDefault();
      const email = evt.target['agent[email]'].value;
      const password = evt.target['agent[password]'].value;
      const remember_me = evt.target['agent[remember_me]'].checked ? 1 : 0;
      dispatch(submitForm());
      dispatch(login(email, password, remember_me, redirect));
    },
  };
};
export default connect(
  mapState,
  null,
  mergeProps,
)(LoginForm);
